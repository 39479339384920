export const fakeProductData = [
    {
        date: 1648133700000,
        store: 'Amazon',
    },
    {
        date: 1648133700000,
        store: 'Ebay',
    },
    {
        date: 1648302900000,
        store: 'AliExpress',
    },
    {
        date: 1648562100000,
        store: 'Ebay',
    },
    {
        date: 1648562100000,
        store: 'Ebay',
    },
    {
        date: 1648648500000,
        store: 'Amazon',
    },
    {
        date: 1648648500000,
        store: 'Amazon',
    },
    {
        date: 1648648500000,
        store: 'Ebay',
    },
    {
        date: 1648821300000,
        store: 'AliExpress',
    },
    {
        date: 1649080500000,
        store: 'AliExpress',
    },
    {
        date: 1649253300000,
        store: 'Wish',
    },
    {
        date: 1649253300000,
        store: 'Amazon',
    },
    {
        date: 1649339700000,
        store: 'Ikea',
    },
    {
        date: 1649685300000,
        store: 'Amazon',
    },
    {
        date: 1649944500000,
        store: 'Wish',
    },
    {
        date: 1649944500000,
        store: 'Amazon',
    },
    {
        date: 1650117300000,
        store: 'Wish',
    },
    {
        date: 1650376500000,
        store: 'Ikea',
    },
    {
        date: 1650549300000,
        store: 'Amazon',
    },
    {
        date: 1651067700000,
        store: 'Wish',
    },
]